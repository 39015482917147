// extracted by mini-css-extract-plugin
export var alignDiscLeft = "p_qv d_fn d_bF d_dt";
export var alignLeft = "p_qw d_fn d_bF d_dt";
export var alignDiscCenter = "p_qx d_fp d_bC d_dv";
export var alignCenter = "p_bN d_fp d_bC d_dv";
export var alignDiscRight = "p_qy d_fq d_bG d_dw";
export var alignRight = "p_qz d_fq d_bG d_dw";
export var footerContainer = "p_qB d_dV d_bV";
export var footerContainerFull = "p_qC d_dS d_bV";
export var footerHeader = "p_kf d_kf";
export var footerTextWrapper = "p_qD d_v";
export var footerDisclaimerWrapper = "p_km d_km d_cj";
export var badgeWrapper = "p_qF d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "p_qG undefined";
export var footerDisclaimerLeft = "p_qH undefined";
export var verticalTop = "p_qJ d_by d_bH d_bM d_bK";
export var firstWide = "p_qK";
export var disclaimer = "p_qL d_by d_bH";
export var socialDisclaimer = "p_qM";
export var left = "p_qN";
export var wide = "p_qP d_cx";
export var right = "p_qQ d_bJ";
export var line = "p_fj d_fk d_ct";
export var badgeDisclaimer = "p_qR d_bB d_bN d_bH";
export var badgeContainer = "p_qS d_by d_bG d_bN";
export var badge = "p_qT";
export var padding = "p_qV d_c6";
export var end = "p_qW d_bG";
export var linkWrapper = "p_qX d_dz";
export var link = "p_mC d_dz";
export var colWrapper = "p_qY d_cw";
export var consent = "p_f d_f d_bB d_bN";
export var media = "p_qZ d_bw d_dx";
export var itemRight = "p_q0";
export var itemLeft = "p_q1";
export var itemCenter = "p_q2";
export var exceptionWeight = "p_q3 s_r2";