// extracted by mini-css-extract-plugin
export var lbContainerOuter = "B_vz";
export var lbContainerInner = "B_vB";
export var movingForwards = "B_vC";
export var movingForwardsOther = "B_vD";
export var movingBackwards = "B_vF";
export var movingBackwardsOther = "B_vG";
export var lbImage = "B_vH";
export var lbOtherImage = "B_vJ";
export var prevButton = "B_vK";
export var nextButton = "B_vL";
export var closing = "B_vM";
export var appear = "B_vN";