// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_s4 d_gv d_cr";
export var heroHeaderCenter = "w_gw d_gw d_cr d_dv";
export var heroHeaderRight = "w_gx d_gx d_cr d_dw";
export var heroParagraphLeft = "w_s5 d_gr d_cv";
export var heroParagraphCenter = "w_gs d_gs d_cv d_dv";
export var heroParagraphRight = "w_gt d_gt d_cv d_dw";
export var heroBtnWrapperLeft = "w_s6 d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "w_s7 d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "w_s8 d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "w_s9 d_gq d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "w_tb d_gp d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "w_sq s_sq";
export var heroExceptionNormal = "w_sr s_sr";
export var heroExceptionLarge = "w_ss s_ss";
export var Title1Small = "w_r5 s_r5 s_rB s_rC";
export var Title1Normal = "w_r6 s_r6 s_rB s_rD";
export var Title1Large = "w_r7 s_r7 s_rB s_rF";
export var BodySmall = "w_sg s_sg s_rB s_rV";
export var BodyNormal = "w_sh s_sh s_rB s_rW";
export var BodyLarge = "w_sj s_sj s_rB s_rX";