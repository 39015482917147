// extracted by mini-css-extract-plugin
export var carouselContainer = "D_qd d_v d_G d_bd d_Y";
export var carouselContainerCards = "D_vQ D_qd d_v d_G d_bd d_Y";
export var carouselContainerSides = "D_vR d_v d_G d_Y";
export var prevCarouselItem = "D_vS d_v d_G d_Z d_j";
export var prevCarouselItemL = "D_vT D_vS d_v d_G d_Z d_j";
export var moveInFromLeft = "D_vV";
export var prevCarouselItemR = "D_vW D_vS d_v d_G d_Z d_j";
export var moveInFromRight = "D_vX";
export var selectedCarouselItem = "D_vY d_v d_G d_Y d_bd";
export var selectedCarouselItemL = "D_vZ D_vY d_v d_G d_Y d_bd";
export var selectedCarouselItemR = "D_v0 D_vY d_v d_G d_Y d_bd";
export var nextCarouselItem = "D_v1 d_v d_G d_Z d_j";
export var nextCarouselItemL = "D_v2 D_v1 d_v d_G d_Z d_j";
export var nextCarouselItemR = "D_v3 D_v1 d_v d_G d_Z d_j";
export var arrowContainer = "D_v4 d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainer = "D_v5 D_v4 d_G d_Z d_dz d_bk d_3 d_b0";
export var prevArrowContainerHidden = "D_v6 D_v5 D_v4 d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var nextArrowContainer = "D_v7 D_v4 d_G d_Z d_dz d_bk d_3 d_b0";
export var carouselContainerProducts = "D_v8";
export var nextArrowContainerHidden = "D_v9 D_v7 D_v4 d_G d_Z d_dz d_bk d_3 d_b0 d_j";
export var arrow = "D_kH d_Z";
export var prevArrow = "D_wb D_kH d_Z";
export var nextArrow = "D_wc D_kH d_Z";
export var carouselIndicatorContainer = "D_wd d_v d_dv d_bk d_by d_bC d_bN d_bH d_Z";
export var btnsContainer = "D_wf d_v d_by d_bD";
export var carouselText = "D_wg d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var carouselTextInactive = "D_wh D_wg d_dp d_by d_bC d_bN d_b0 d_dz d_cC";
export var indicator = "D_wj d_b6";
export var carouselIndicator = "D_wk D_wj d_b6";
export var carouselIndicatorSelected = "D_wl D_wj d_b6";
export var arrowsContainerTopRight = "D_wm d_Z d_bk d_bB";
export var arrowsContainerBottomLeft = "D_wn d_Z d_bk d_bB";
export var arrowsContainerSides = "D_wp d_Z d_bk d_bB";
export var smallArrowsBase = "D_wq d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainer = "D_wr D_wq d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrev = "D_ws D_wr D_wq d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerSmall = "D_wt D_wq d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var smallArrowContainerPrevSmall = "D_wv D_wt D_wq d_bS d_by d_bC d_bN d_b0 d_dz d_cC";
export var icon = "D_pD";
export var iconSmall = "D_ww";
export var multipleWrapper = "D_wx d_bB d_bD d_bd";
export var multipleImage = "D_wy d_bB";
export var sidesPrevContainer = "D_wz D_wr D_wq d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";
export var sidesNextContainer = "D_wB D_wr D_wq d_bS d_by d_bC d_bN d_b0 d_dz d_cC d_fm d_Z d_bk d_cC";