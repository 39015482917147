// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "x_tc d_bC";
export var storyRowWrapper = "x_hx d_hx d_bJ";
export var storyLeftWrapper = "x_td d_by d_bN";
export var storyWrapperFull = "x_tf d_cC";
export var storyWrapperFullLeft = "x_nN d_cC d_by d_bN d_bC";
export var contentWrapper = "x_pb d_hy";
export var storyLeftWrapperCenter = "x_tg d_hz d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "x_th d_hF";
export var storyHeader = "x_tj d_hD d_v d_cr";
export var storyHeaderCenter = "x_hC d_hC d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "x_hB d_hB d_bx d_dv";
export var storyBtnWrapper = "x_tk d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "x_hJ d_hJ d_d0 d_v d_by d_bC";
export var imageWrapper = "x_tl d_ff d_Y";
export var imageWrapperFull = "x_tm d_v d_G d_bd d_Y";