// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "y_fQ d_fQ d_by d_bG d_bH";
export var navbarDividedRight = "y_fR d_fR d_by d_bH";
export var menuDesign6 = "y_tn d_fM d_by d_dv d_bN d_bH";
export var menuDesign7 = "y_tp d_fM d_by d_dv d_bN d_bH";
export var menuRight = "y_tq d_fM d_by d_dv d_bN d_bH";
export var menuLeft = "y_tr d_fM d_by d_dv d_bN d_bH";
export var menuCenter = "y_ts d_fN d_fM d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "y_q9 d_fN d_fM d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "y_rl d_fP d_fM d_by d_dv d_bN d_bH";
export var isBurger = "y_tt";
export var navbarItem = "y_rb d_bw";
export var navbarLogoItemWrapper = "y_fY d_fY d_bB d_bN";
export var burgerToggleVisibleOpen = "y_tv d_gd d_bx d_Y d_br";
export var burgerToggleVisible = "y_tw d_gd d_bx d_Y d_br";
export var burgerToggle = "y_tx d_gd d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "y_ty d_gd d_bx d_Y d_br";
export var burgerInput = "y_tz d_f2 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "y_tB d_f3 d_v d_G";
export var burgerLine = "y_tC d_f1";
export var burgerMenuDesign6 = "y_tD d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "y_tF d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "y_tG d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "y_tH d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "y_tJ d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "y_tK d_f9 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "y_tL d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var secondary = "y_tM d_bB d_bN";
export var staticBurger = "y_tN";
export var menu = "y_tP";
export var navbarDividedLogo = "y_tQ";
export var nav = "y_tR";