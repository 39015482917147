// extracted by mini-css-extract-plugin
export var noMargin = "m_qb d_cb";
export var carouselCol = "m_qc d_cC";
export var carouselWrapper = "m_k7 d_k7 d_by d_bC d_bN d_v d_G";
export var carouselImageWrapper = "m_k8 d_k8 d_G d_v";
export var carouselContainer = "m_qd d_cC";
export var carouselContainerFull = "m_qf d_dS";
export var carouselImg = "m_mP d_k9 d_v d_G d_bQ";
export var imageTextWrapperLeft = "m_k2 d_k2 d_k1 d_Z d_bS";
export var imageTextWrapperCenter = "m_k3 d_k3 d_k1 d_Z d_bS";
export var imageTextWrapperRight = "m_k4 d_k4 d_k1 d_Z d_bS";
export var imageTextWrapperHidden = "m_k0 d_k0";
export var imageAltSmall = "m_qg d_k5";
export var imageAltNormal = "m_qh d_k5";
export var imageAltLarge = "m_qj d_k5";
export var imageTextSmall = "m_qk d_k6";
export var imageTextNormal = "m_ql d_k6";
export var imageTextLarge = "m_qm d_k6";